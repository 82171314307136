"use client";

import { captureException } from "@sentry/nextjs";
import Error from "next/error";
import { useEffect } from "react";

export default function GlobalError({ error }) {
  let title = undefined;
  if (!window.navigator.onLine)
    title =
      "Không có kết nối internet. Vui lòng kết nối với internet và thử lại.";
  return (
    <html>
      <body>
        <Error title={title} />
      </body>
    </html>
  );
}
